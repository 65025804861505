import React from 'react';
import classNames from 'classnames';

import SEO from '../components/SEO';
import LegalNav from '../components/LegalNav';
import RenderStaticHTML from '../components/RenderStaticHTML';

import termsContent from '../data/terms-content.js';

const nsBase = 'page';
const ns = `${ nsBase }-legal`;

const pageContent = termsContent;

const TermsOfUse = () => {
	const rootClassnames = classNames({
		[`page-terms-of-use`]: true,
		[`${ ns }`]: true,
	});

	return (
		<div className={rootClassnames}>
			<SEO title={'Terms of Use'} />
			<LegalNav />
			<div className={`${ ns }__page`}>
				<div className={'container-fluid'}>
					<h1 className={`${ ns }__title`}>Terms of Use</h1>
					<div className={`${ ns }__content`}>
						<RenderStaticHTML html={pageContent} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default TermsOfUse;
