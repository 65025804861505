import React, { useState } from 'react';
import classNames from 'classnames';

import Link from '../Link';

const nsBase = 'component';
const ns = `${ nsBase }-legal-nav`;

const LegalNav = () => {
	const [navActive, setNavActive] = useState(false);

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`active`]: navActive,
	});

	return (
		<div className={rootClassnames}>
			<div className={'container-fluid'}>
				<div className={`${ ns }__container`}>
					<div className={`${ ns }__title`}>
						<button onClick={() => { setNavActive(!navActive); }}>Legal</button>
						<div className={`${ ns }__dropdown-arrow`}>
							<svg width={'17px'} height={'10px'} viewBox={'0 0 17 10'} version={'1.1'} xmlns={'http://www.w3.org/2000/svg'} xmlnsXlink={'http://www.w3.org/1999/xlink'}>
								<title>Fill 1</title>
								<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
									<g transform={'translate(-298.000000, -195.000000)'}>
										<polygon fill={'#FFFFFF'} points={'313.556 195 306.485 202.071 299.414 195 298 196.415 305.07 203.485 306.485 204.899 314.97 196.415'} />
									</g>
								</g>
							</svg>
						</div>
					</div>
					<div className={`${ ns }__links`}>
						<div className={`${ ns }__links-container`}>
							<Link
								to={'/privacy-policy'}
								activeClassName={'active'}
							>
								Privacy Policies
							</Link>
							<Link
								to={'/terms-of-use'}
								activeClassName={'active'}
							>
								Terms of Use
							</Link>
							<Link
								to={'/warranty'}
								activeClassName={'active'}
							>
								Warranty
							</Link>
							<Link
								to={'/eula'}
								activeClassName={'active'}
							>
								EULA
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LegalNav;
